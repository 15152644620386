import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../../components/Community/Layout"
import { space, color, layout, typography, border } from "styled-system"
import { useAuth0 } from "@auth0/auth0-react"
import { Flex, Title, Text, Box, Button, Label } from "../../components/system"
import { Formik, Form, Field } from "formik"

const Welcome = () => {
  const [userType, setUserType] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const { user, getAccessTokenSilently } = useAuth0()

  // const metadata = user && user["https://ilana.uk/metadata"]

  const submitted = JSON.parse(
    typeof window !== "undefined" && window.localStorage.getItem("payload")
  )

  const createUser = async values => {
    const payload = {
      name: values.name,
      submitted: true,
    }

    await fetch("/.netlify/functions/createUser", {
      method: "POST",
      body: JSON.stringify({
        name: values.name,
        email: user.email,
        type: userType,
        uuid: user.sub,
        brand: values.brand,
        website: values.website,
        challenges: values.challenges,
      }),
    })
      .then(res => res.json())
      .then(data => {
        window !== "undefined" &&
          window.localStorage.setItem("payload", JSON.stringify(payload))
        setFormSubmitted(true)
        addToAuth(data._id, values.name)
      })
      .catch(error => console.log(error))
  }

  const createExpert = async values => {
    const payload = {
      name: values.name,
      submitted: true,
    }

    await fetch("/.netlify/functions/createExpert", {
      method: "POST",
      body: JSON.stringify({
        name: values.name,
        email: user.email,
        type: userType,
        uuid: user.sub,
        expertise: values.expertise,
        skills: values.skills,
      }),
    })
      .then(res => res.json())
      .then(data => {
        window !== "undefined" &&
          window.localStorage.setItem("payload", JSON.stringify(payload))
        setFormSubmitted(true)
        addToAuth(data._id, values.name)
      })
      .catch(error => console.log(error))
  }

  const addToAuth = async (id, name) => {
    const domain = process.env.GATSBY_AUTH0_DOMAIN

    const accessToken = await getAccessTokenSilently({
      audience: `https://${domain}/api/v2/`,
      scope: "update:users",
    })

    const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`

    await fetch(userDetailsByIdUrl, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "PATCH",
      body: JSON.stringify({
        user_metadata: {
          name: name,
          submitted: true,
          avatar:
            "https://cdn.sanity.io/images/ybn5jal1/production/97da26a15d397161ac6024591e9147be985ae339-1080x1080.png",
          id: id,
        },
      }),
    })
  }

  if (submitted?.submitted || formSubmitted) {
    return (
      <Flex height="75vh" px={[4, 7]} flexDirection="column">
        <Title fontSize={[5, 6]}>
          Thank you {submitted.name} for joining, we will contact you once we
          have reviewed your application.
        </Title>
        <Text color="shadow">
          If you have any questions please email{" "}
          <a
            href="mailto:community@ilana.uk"
            style={{ textDecoration: "underline" }}
          >
            community@ilana.uk
          </a>
        </Text>
      </Flex>
    )
  }

  return (
    <Layout>
      <Flex flexDirection="column" mb={7}>
        <>
          <Title fontSize={[5, 6]} my={3} color="secondary" px={[4, 7]}>
            Welcome
          </Title>

          <Title fontSize={5} mt={5}>
            Select your account type
          </Title>

          <Flex
            flexWrap="wrap"
            justifyContent="space-between"
            px={[3, 5, 7]}
            mb={4}
          >
            <Select
              p={3}
              mb={3}
              height={150}
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection="column"
              width={["100%", "47%"]}
              borderRadius={5}
              onClick={() => setUserType("user")}
              border={
                userType === "user" ? "2px solid black" : "2px solid #cecece"
              }
              style={{ cursor: "pointer" }}
            >
              <Label
                down
                color={userType === "user" ? "secondary" : "black"}
                mb={2}
              >
                Brand
              </Label>
              <Text textAlign="left" color="shadow">
                You are a designer or brand looking for expertise.
              </Text>
            </Select>

            <Select
              p={3}
              mb={3}
              height={150}
              justifyContent="flex-start"
              alignItems="flex-start"
              flexDirection="column"
              width={["100%", "47%"]}
              borderRadius={5}
              onClick={() => setUserType("expert")}
              border={
                userType === "expert" ? "2px solid black" : "2px solid #cecece"
              }
              style={{ cursor: "pointer" }}
            >
              <Label
                down
                color={userType === "expert" ? "secondary" : "black"}
                mb={2}
              >
                Consultant
              </Label>
              <Text textAlign="left" color="shadow">
                You would like to join our community of fashion experts and
                offer expertise to emerging designers.
              </Text>
            </Select>
          </Flex>

          {userType === null ? null : userType === "user" ? (
            <Box px={[3, 7]} width="100%">
              <Formik
                initialValues={{
                  name: "",
                  brand: "",
                  website: "",
                  challenges: [],
                }}
                validate={values => {
                  const errors = {}

                  return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    createUser(values)
                    setSubmitting(false)
                  }, 400)
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Flex
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      width={["100%", "50vw"]}
                    >
                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What's your name?
                        </Title>
                        <TextInput
                          p={3}
                          type="text"
                          name="name"
                          placeholder="Jane Doe"
                          autoComplete="off"
                        />
                      </Box>
                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What's the name of your Brand?
                        </Title>
                        <TextInput
                          width={["100%", 400]}
                          p={3}
                          type="select"
                          name="brand"
                          placeholder="Acme Studios"
                          autoComplete="off"
                        />
                      </Box>

                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          Please link your brands website
                        </Title>
                        <TextInput
                          width={["100%", 400]}
                          p={3}
                          type="select"
                          name="website"
                          placeholder="https://off-white.com"
                          autoComplete="off"
                        />
                      </Box>

                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What challenges do you face as a Brand?
                        </Title>

                        <Flex
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="Market Overcrowding"
                            />
                            <Label down ml={3}>
                              Market Overcrowding
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="Visibility & Exposure"
                            />
                            <Label down ml={3}>
                              Visibility & Exposure
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="General Business Knowledge"
                            />
                            <Label down ml={3}>
                              General Business Knowledge
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="Sales"
                            />
                            <Label down ml={3}>
                              Sales
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="Funding"
                            />
                            <Label down ml={3}>
                              Funding
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="challenges"
                              value="Marketing"
                            />
                            <Label down ml={3}>
                              Marketing
                            </Label>
                          </Flex>
                        </Flex>
                      </Box>

                      <Button
                        bg="black"
                        color="white"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting.." : "Submit"}
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Box>
          ) : (
            <Box px={[3, 7]} width="100%">
              <Formik
                initialValues={{ name: "", expertise: [], skills: [] }}
                validate={values => {
                  const errors = {}

                  return errors
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    createExpert(values)
                    setSubmitting(false)
                  }, 400)
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Flex
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      width={["100%", "50vw"]}
                    >
                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What's your name?
                        </Title>
                        <TextInput
                          p={3}
                          type="text"
                          name="name"
                          placeholder="Jane Doe"
                          autoComplete="off"
                        />
                      </Box>

                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What is your expertise?
                        </Title>

                        <Flex
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="expertise"
                              value="Garment Technologist"
                            />
                            <Label down ml={3}>
                              Garment Technologist
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="expertise"
                              value="Design"
                            />
                            <Label down ml={3}>
                              Design
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="expertise"
                              value="Manufacturing"
                            />
                            <Label down ml={3}>
                              Manufacturing
                            </Label>
                          </Flex>
                        </Flex>
                      </Box>

                      <Box mb={4}>
                        <Title fontSize={[4, 5]} mb={2}>
                          What are your skills and experience?
                        </Title>

                        <Flex
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Fashion Sketch"
                            />
                            <Label down ml={3}>
                              Fashion Sketch
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="CAD Design & Technical Card"
                            />
                            <Label down ml={3}>
                              CAD Design & Technical Card
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Pattern Cutting & Grading"
                            />
                            <Label down ml={3}>
                              Pattern Cutting & Grading
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Fabric Cutting"
                            />
                            <Label down ml={3}>
                              Fabric Cutting
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="CMT"
                            />
                            <Label down ml={3}>
                              CMT
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Sewing"
                            />
                            <Label down ml={3}>
                              Sewing
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Sampling"
                            />
                            <Label down ml={3}>
                              Sampling
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Tailoring"
                            />
                            <Label down ml={3}>
                              Tailoring
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Textiles"
                            />
                            <Label down ml={3}>
                              Textiles
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Haberdashery & Fastening"
                            />
                            <Label down ml={3}>
                              Haberdashery & Fastening
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Quality Control"
                            />
                            <Label down ml={3}>
                              Quality Control
                            </Label>
                          </Flex>
                          <Flex mb={3} justifyContent="flex-start">
                            <CheckBox
                              type="checkbox"
                              name="skills"
                              value="Sewing Machines & Technology"
                            />
                            <Label down ml={3}>
                              Sewing Machines & Technology
                            </Label>
                          </Flex>
                        </Flex>
                      </Box>

                      <Button
                        bg="black"
                        color="white"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Submitting.." : "Submit"}
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Box>
          )}
        </>
      </Flex>
    </Layout>
  )
}

export default Welcome

const Select = styled(Flex)({
  // border: "2px solid #cecece",
  "&:hover": {
    border: "2px solid black",
  },
})

const TextInput = styled(Field)(
  {
    color: "black",
    fontFamily: "Manrope",
    border: "2px solid #cecece",
    borderRadius: 5,
    width: "100%",
    "&::placeholder": {
      color: "#cecece",
    },
  },
  space,
  color,
  layout,
  typography,
  border
)

const CheckBox = styled(Field)({
  height: 30,
  width: 30,
})
